import { venueRepository } from "@/service/repository/venueRepository";
import store from "@/store";

const state = {
	resourceId: null,
	subdomain: null,
	businessName: null,
	venueId: null,
	reservationManagerUrl: null,
	reservationAgent: null,
	menuServices: null,
	disableDay: [],
	weOrderClientComments: false,
	reviewGoogleMapsUrl: "",
	paymentConfig: null,
	copilotMultipleServiceEvent: null,
	locales: [],
	serviceSchedule: [],
	reservationPrePaymentConfig: [] | null,
	fiscalInfo: [] | null,
	reservationLocationTags: [] | null,
	elaborationTypes: [] | null,
	printEnabled: false,
	locationGmapsUrl: null,
};

const getters = {
	resourceId(state) {
		return state.resource_id;
	},
	urlVenue(state) {
		const subdomain = state.subdomain;
		return `https://${subdomain}.wewelcom.io`;
	},
	businessName(state) {
		return state.businessName;
	},
	venueId(state) {
		return state.venueId;
	},
	locales(state) {
		return state.locales;
	},
	reservationManagerUrl(state) {
		return state.reservationManagerUrl;
	},
	reservationAgent(state) {
		return state.reservationAgent;
	},
	menuServices(state) {
		return state.menuServices;
	},
	serviceSchedule(state) {
		return state.serviceSchedule;
	},
	disableDay(state) {
		return state.disableDay;
	},
	weOrderClientComments(state) {
		return state.weOrderClientComments;
	},
	reviewGoogleMapsUrl(state) {
		return state.reviewGoogleMapsUrl;
	},
	paymentConfig(state) {
		return state.paymentConfig;
	},
	copilotMultipleServiceEvent(state) {
		return state.copilotMultipleServiceEvent;
	},
	reservationPrePaymentConfig(state) {
		return state.reservationPrePaymentConfig;
	},
	printEnabled(state) {
		return state.printEnabled;
	},
	fiscalInfo(state) {
		return state.fiscalInfo;
	},
	reservationLocationTags(state) {
		return state.reservationLocationTags;
	},
	elaborationTypes(state) {
		return state.elaborationTypes;
	},
	locationGmapsUrl(state) {
		return state.locationGmapsUrl;
	},
};

const mutations = {
	initConfigVenue(state, payload) {
		// state.welcomTheme = payload.welcom_theme;
		state.subdomain = payload.subdomain;
		state.businessName = payload.business_name;
		state.venueId = payload.id;
		state.reservationManagerUrl = payload.reservationManagerUrl;
		state.resource_id = payload.resource_id;
		state.reservationAgent = payload.reservation_agent;
		state.menuServices = payload.menuServices;
		if (payload.reservation_disabled_dow) {
			state.disableDay.push(payload.reservation_disabled_dow);
		}
		state.weOrderClientComments = payload.we_order_client_comments || false;
		state.reviewGoogleMapsUrl = payload.reviewGoogleMapsUrl || "";
		state.paymentConfig = payload.paymentConfig;
		state.copilotMultipleServiceEvent = payload.copilot_multiple_service_event_product_orders;
		state.locales = payload.locales;
		state.serviceSchedule = payload.serviceSchedule;
		state.reservationPrePaymentConfig = payload.reservationPrePaymentConfig;
		state.printEnabled = payload.printEnabled || false;
		state.fiscalInfo = payload.fiscalInfo;
		state.reservationLocationTags = payload.reservationLocationTags;
		state.elaborationTypes = payload.elaborationTypes;
		state.locationGmapsUrl = payload.location_gmaps_url;
		store.commit("accounting/setCashDrawers", { cashDrawers: payload.cashDrawers });
	},
	setElaborationTypes(state, payload) {
		state.elaborationTypes = payload.elaborationTypes;
	},
};

const actions = {
	async loadVenueConfig({ commit }) {
		try {
			const config = await venueRepository.getVenueConfig();
			if (config.result === 0) {
				commit("initConfigVenue", config.venueConfig);
			}
		} catch (error) {
			console.error("Failed to fetch venue config", error);
		}
	},
	async loadElaborationTypes({ commit }) {
		try {
			const getElaborationTypeResult = await venueRepository.getElaborationTypes();
			if (getElaborationTypeResult.result === 0) {
				commit("setElaborationTypes", getElaborationTypeResult.elaborationTypes);
			}
		} catch (error) {
			console.error("Failed to fetch venue elaborationTypes", error);
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
