import { viewSectionStandar } from "@/utils";

const getDefaultState = () => ({
	isLoading: false,
	viewSectionAccounts: "allAccounts",
	viewSectionItems: "topChoice",

	accountSelectedId: null,
	newAccount: null,
	isChangeTable: false,
	descriptionZone: null,

	unifyAccounts: {
		originReservationId: null,
		destinationReservationId: null,
	},

	destinationAccountProductsOrders: null,
	listProductsSelected: [],

	isAccountSelectedForUnify: false,
	isAddProductsCartInAccount: false,
	isAccountMoveProducts: false,
});

const state = getDefaultState();

const getters = {
	isLoading: (state) => {
		return state.isLoading;
	},
	viewSectionAccounts: (state) => {
		return state.viewSectionAccounts;
	},
	viewSectionItems: (state) => {
		return state.viewSectionItems;
	},

	accountSelectedId: (state) => {
		return state.accountSelectedId;
	},
	newAccount: (state) => {
		return state.newAccount;
	},
	isChangeTable: (state) => {
		return state.isChangeTable;
	},

	descriptionZone: (state) => {
		return state.descriptionZone;
	},
	unifyAccounts: (state) => {
		return state.unifyAccounts;
	},
	destinationAccountProductsOrders: (state) => {
		return state.destinationAccountProductsOrders;
	},

	listProductsSelected: (state) => {
		return state.listProductsSelected;
	},

	isAccountSelectedForUnify: (state) => {
		return state.isAccountSelectedForUnify;
	},

	isAddProductsCartInAccount: (state) => {
		return state.isAddProductsCartInAccount;
	},
	isAccountMoveProducts: (state) => {
		return state.isAccountMoveProducts;
	},
};

const mutations = {
	setIsLoading: (state, payload) => {
		state.isLoading = payload;
	},
	setViewSectionAccounts(state, sectionAccounts) {
		state.viewSectionAccounts = sectionAccounts;
	},
	setViewSectionItems(state, sectionItems) {
		state.viewSectionItems = sectionItems;
	},

	setAccountSelectedId(state, accountId) {
		state.accountSelectedId = accountId;
	},
	setNewAccount(state, newAccount) {
		state.newAccount = newAccount;
	},

	updateNewAccount(state, payload) {
		state.newAccount = {
			...state.newAccount,
			...payload,
		};
	},

	setIsChangeTable(state, payload) {
		state.isChangeTable = payload;
	},
	setDescriptionZone(state, payload) {
		state.descriptionZone = payload;
	},

	setDestinationAccountProductsOrders(state, payload) {
		state.destinationAccountProductsOrders = payload;
	},
	setIsAccountMoveProducts(state, payload) {
		state.isAccountMoveProducts = payload;
	},
	addListProductsSelected(state, productId) {
		if (!state.listProductsSelected.includes(productId)) {
			state.listProductsSelected.push(productId);
		}
	},
	removeListProductsSelected(state, productId) {
		state.listProductsSelected = state.listProductsSelected.filter((id) => id !== productId);
	},
	clearListProductsSelected(state) {
		state.listProductsSelected = [];
	},

	setUnifyAccounts(state, payload) {
		state.unifyAccounts = {
			...state.unifyAccounts,
			...payload,
		};
	},
	setIsAccountSelectedForUnify(state, payload) {
		state.isAccountSelectedForUnify = payload;
	},
	setIsAddProductsCartInAccount(state, payload) {
		state.isAddProductsCartInAccount = payload;
	},

	resetState(state) {
		Object.assign(state, getDefaultState());
	},
};

const actions = {
	updateProductSelection({ commit }, { productId, isSelected }) {
		if (isSelected) {
			commit("addListProductsSelected", productId);
		} else {
			commit("removeListProductsSelected", productId);
		}
	},

	resetAllStates({ commit }) {
		commit("resetState");
		viewSectionStandar();
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
};
