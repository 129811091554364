import {configRepository} from "@/service/repository/configRepository";
import {DateTime} from "luxon";

const state = {
	loadingScreen: true,
    websocketUrl: null,
    websocketPort: null,
	wePassUrl: null,
	weWelcomUrl: null,
	wsConnectionStatus: 3,
	logicalDate: null,
};

const getters = {
	websocketUrl(state) {
		return state.websocketUrl;
	},
	websocketPort(state) {
		return state.websocketPort;
	},
	weWelcomUrl(state) {
		return state.weWelcomUrl;
	},
	wePassUrl(state) {
		return state.wePassUrl;
	},
	wsConnectionStatus(state) {
		return state.wsConnectionStatus;
	},
	isProductionEnvironment(state){
		return state.weWelcomUrl === "https://wewelcom.io";
	},
	isTestEnvironment(state){
		return state.weWelcomUrl === "https://test.wewelcom.io";
	},
	isDevEnvironment(state){
		return state.weWelcomUrl === "https://dev.wewelcom.io";
	},
	logicalDate(state){
		return state.logicalDate ?? DateTime.now().toISODate();
	},
	isLoadingScreen: state => state.loadingScreen
};

const mutations = {
	setWsConnectionStatus(state, payload){
		state.wsConnectionStatus = payload.wsConnectionStatus;
	},
	initConfig(state, payload){
		state.websocketUrl = payload.websocket_url;
		state.websocketPort = payload.websocket_port;
		state.wePassUrl = payload.wepass_url;
		state.weWelcomUrl = payload.wewelcom_url;
	},
	initLogicalDate(sate, payload){
		state.logicalDate = payload;
	},
	setLoadingScreen(state, status) {
		state.loadingScreen = status;
	}
};

const actions = {
	async loadSystemConfig({ commit }) {
		try {
			const configResult = await configRepository.loadSystemConfig();
			commit('initConfig', configResult.systemConfig);
			commit('initLogicalDate', configResult.logicalDate)
		} catch (error) {
			console.error("Failed to fetch system config", error);
		}
	},
	showLoadingScreen({ commit }) {
		commit('setLoadingScreen', true);
	},
	hideLoadingScreen({ commit }) {
		commit('setLoadingScreen', false);
	}
}

export default {
    namespaced: true,
    state,
    mutations,
	actions,
	getters
};
