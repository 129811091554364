import {sendRequestJson} from '@/utils'

class VenueRepository {
	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('VenueRepository con urlBase: ', this.urlBase)
	}

	async getVenueConfig() {
		const url = this.urlBase + "/world/api/venueconfig"
		try {
			return await sendRequestJson({data:{}, url, method:"POST"}, false);
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo la información de la venue.");
		}
	}
	async getElaborationTypes() {
		const url = this.urlBase + "/world/api/elaborationtype/get"
		try {
			return await sendRequestJson({data:{}, url, method:"POST"}, false);
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo los elaborationTypes.");
		}
	}
	async getVenueMenus() {
		const url = this.urlBase + "/world/api/venuemenus"
		try {
			return await sendRequestJson({data:{}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo los menus de la venue.");
		}
	}
}

export const venueRepository = new VenueRepository();
