<template>
	<LoadingScreen v-if="isLoadingScreen" />
	<router-view v-else></router-view>
	<toast-welcom></toast-welcom>
	<ModalNotificationWelcom></ModalNotificationWelcom>
	<ModalPrivacy />
</template>

<script>
import ToastWelcom from "@/components/toastWelcom/ToastWelcom";
import ModalNotificationWelcom from "@/components/modalNotification/ModalNotification";
import { mapGetters } from "vuex";
import LoadingScreen from "@/core/LoadingScreen";
import ModalPrivacy from "@/components/mods/modalPrivacy/ModalPrivacy.vue";

export default {
	name: "App",
	components: { ModalPrivacy, LoadingScreen, ModalNotificationWelcom, ToastWelcom },
	data() {
		return {
			showExitModal: false,
			preventExit: true,
		};
	},
	computed: {
		...mapGetters("venue", ["resourceId"]),
		...mapGetters("config", ["isLoadingScreen"]),
	},
	methods: {},
	mounted() {
		/*window.addEventListener("beforeunload", (event) => {
			event.preventDefault();
			event.returnValue = "";
			return "";
		});*/
	},
	beforeUnmount() {},
};
</script>
