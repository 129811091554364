import {sendRequestJson} from '@/utils'

export class AccountingRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('Accounting con urlBase: ', this.urlBase)
	}

	async getAccountingConfig() {
		const url = this.urlBase + "/world/api/accounting-config"
		try {
			const data = {};
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal.");
		}
	}

	async registerAccountingSnapshot(data) {
		const url = this.urlBase + "/world/api/accounting/create-accounting-snapshot"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal.");
		}
	}

	async registerCashDrawerTransaction(data) {
		const url = this.urlBase + "/world/api/accounting/cash-drawer-transaction"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal.");
		}
	}

}

export const accountingRepository = new AccountingRepository();
