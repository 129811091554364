import {hideAllOffcanvas} from "@/utils";
import {accountingRepository} from "@/service/repository/accountingRepository";
import store from "@/store/index";

const state = {
	slots: [],
	cashDrawers: [],
	cardReaders: [],
	venueProviders: [],
	snapsHotTotalCardAmount: null
};

const getters = {
	cashDrawers(state){
		return state.cashDrawers;
	},
	mainCashDrawer(state){
		return state.cashDrawers[0];
	},
	cardReaders(state){
		return state.cardReaders;
	},
	mainCardReader(state){
		return state.cardReaders[0];
	},
	slots(state){
		return state.slots;
	},
	snapsHotTotalCardAmount(state){
		return state.snapsHotTotalCardAmount;
	},
	venueProviders(state){
		return state.venueProviders;
	}
};

const mutations = {
	initConfigAccounting(state, payload){
		state.cashDrawers = payload.cashDrawers
		state.cardReaders = payload.cardReaders
		state.venueProviders = payload.venueProviders

		state.slots = state.cashDrawers[0].slots.map(slot => ({
			...slot,
			quantity: null
		}));
	},
	setCashDrawers(state, payload){
		state.cashDrawers = payload.cashDrawers
	},
	updateSlot(state, payload){
		state.slots = state.slots.map(slot => {
			return slot.value === payload.value ? {...slot, quantity: payload.quantity} : slot
		})
	},
	setSlots(state,payload){
		state.slots = payload.slots
	},
	setsSnapsHotTotalCardAmount(state,payload){
		state.snapsHotTotalCardAmount = payload.snapsHotTotalCardAmount
	},
	resetAccountingSnapshotData(state){
		state.snapsHotTotalCardAmount = null;
		state.slots = state.slots.map(slot => {
			return  {...slot, quantity: null}
		})
	}

};

const actions = {
	async loadAccountingConfig({ commit }) {
		try {
			const config = await accountingRepository.getAccountingConfig();
			if(config.result === 0){
				commit('initConfigAccounting', config.accountingConfig);
			}
		} catch (error) {
			console.error("Failed to fetch venue config", error);
		}
	},
	async registerAccountingSnapshot({ getters }) {
		try {
			const data = {
				cardReaderId: getters.mainCardReader.id,
				totalCardAmount: getters.snapsHotTotalCardAmount,
				cashDrawerId: getters.mainCashDrawer.id,
				cashDrawerSlots: getters.slots.map(slot => {
					return {cashDenominationId: slot.cashDenominationId, quantity: slot.quantity}
				})
			};

			return await accountingRepository.registerAccountingSnapshot(data);

		} catch (error) {
			console.error("Error al guardar el snapshot:", error);
		}
	},
	async registerCashDrawerTransaction({getters }, payload) {
		try {
			const data = {
				cashDrawerId: getters.mainCashDrawer.id,
				totalAmount: payload.totalAmount,
				cashDrawerTransitionType: payload.cashDrawerTransitionType,
				venueProviderName: payload.venueProviderName
			};
			const result = await accountingRepository.registerCashDrawerTransaction(data)

			if (result.result === 0){
				store.commit("copilot/setToast", {toast:{toastMessage: 'Transacción de caja registrada correctamente', showToast:true, idToast: "correctlyToast"}});
				hideAllOffcanvas();
			} else {
				store.commit("copilot/setToast", {toast:{toastMessage: 'Ha ocurrido un error', showToast:true, idToast: "incorrectlyToast"}});
			}
			return result;
		} catch (error) {
			console.error("Error al guardar el snapshot:", error);
		}
	}
};



export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
