import store from "@/store";

export class SpeakService {

	constructor() {
		this.audioContext = new AudioContext();
		this.kitchenOrderReadyAlertPath = 'https://wewelcom.io/world/snd/bellring.mp3';
		this.weNotificationAlertPath = 'https://wewelcom.io/world/snd/notification-we.mp3';
		this.checkCopilotMessagePath = 'https://wewelcom.io/world/snd/we-connection-check.mp3';
		this.currentAudio = null;
		this.notificationQueue = [];
		this.isPlaying = false;
	}

	playAudio (audioUrl){
		return new Promise((resolve, reject) => {
			if (this.currentAudio) {
				this.currentAudio.pause();
				this.currentAudio.currentTime = 0;
			}

			this.currentAudio = new Audio(audioUrl);
			//this.currentAudio.volume = 0.7;
			this.currentAudio.onended = () => {
				resolve();
				this.currentAudio = null;
			};

			this.currentAudio.onerror = (err) => {
				reject(err);
			};

			this.currentAudio.play().catch(err => {
				if (err.message.indexOf("interrupted by a call to pause") < 0) {
					console.error(err);
				}
			});
		})
	}

	async unlockAudio() {
		// Call this on the first user interaction
		if (this.audioContext.state === 'suspended') {
			console.log("Audio bloqueado. Intentando desbloqueo");
			try {
				await this.audioContext.resume();
				console.log("Audio desbloqueado")
			} catch (e){
				console.error(e);
				console.log("Error desbloqueando")
			}
		} else {
			console.log("El canal de audio no estaba bloqueado");
		}
	}

	isAudioContextRunning(){
		return this.audioContext.state === 'running';
	}

	async processQueue() {
		if (this.isPlaying || this.notificationQueue.length === 0) {
			console.log("No se reproduce la notifiación porque ya está sonando o la cola está vacía: isPlaying = "+this.isPlaying+" y elementos en cola: "+this.notificationQueue.length);
			//this.isPlaying = false;
			return;
		}
		const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

		while (this.notificationQueue.length > 0) {
			const notificationMode = store.getters['copilot/notificationMode'];
			const msDelay = Number(notificationMode) * 60 * 1000;
			const { notification, playAlertSound } = this.notificationQueue.shift();
			try {
				console.log(notificationMode);
				this.isPlaying = true;
				await this.playNotification(notification, playAlertSound);
				await delay(msDelay);
			} catch (error) {
				this.isPlaying = false;
				console.error('Error playing notification sound:', error);
			}
		}

		this.isPlaying = false;
	}

	async enqueueNotification(notification, playAlertSound = true) {
		//await this.unlockAudio();
		if (this.audioContext.state !== 'running') {
			console.log("AudioContext no está listo; dropping notification");
			return;
		}
		console.log("AudioContext libre, encolando notificación");
		this.notificationQueue.push({ notification, playAlertSound });
		await this.processQueue();
	}

	async playNotificationAlertSound() {
		await this.playAudio(this.weNotificationAlertPath);
	}

	async playKitchenOrderReadyNotificationAlertSound() {
		await this.playAudio(this.kitchenOrderReadyAlertPath);
	}

	async playCheckCopilotSound() {
		await this.playAudio(this.checkCopilotMessagePath);
	}

	async playNotificationMessageAudio(notification) {
		let url = store.getters["config/weWelcomUrl"];
		let audioSource = url+"/world/notification/audio/"+notification.id;
		console.log("queremos reproducir "+audioSource);
		await this.playAudio(audioSource);
	}

	async playHavePendingNotifications(audioSource){
		console.log(audioSource)
		await this.playAudio(audioSource);
	}

	async playNotification(notification, playAlertSound = true) {
		await this.unlockAudio();
		try {
			if (playAlertSound) {
				console.log("Reproduciendo notificación",notification)
				if (notification.type === "order_ready_food") {
					await this.playKitchenOrderReadyNotificationAlertSound();
				} else {
					await this.playNotificationAlertSound();
				}
			}
			await this.playNotificationMessageAudio(notification);
		} catch (e){
			this.isPlaying = false;
		}

	}

	async speak(message, playAlertSound = true) {
		let msg = new SpeechSynthesisUtterance(message);
		msg.rate = 1.0;
		msg.pitch = 1;
		msg.lang = 'es-ES';
		if (playAlertSound) {
			await this.playNotificationAlertSound()
		}
		setTimeout(function () {
			window.speechSynthesis.speak(msg);
		}, 1000)
	}
}

export const speakService = new SpeakService();
