import {sendRequest, sendRequestJson, sendRequestPdf} from '@/utils'

export class ReservationRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('ReservationRepository con urlBase: ', this.urlBase)
	}

	async find(reservationId) {
		const data = {reservationId}
		const url = this.urlBase + "/world/api/reservation/reservationinfo"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo la informacion de la reserva.");
		}
	}

	async create(data) {
		const url = this.urlBase + "/world/reservation/register"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal creando la reserva.");
		}
	}

	async update({client, data}) {
		const url = `https://${client}.wewelcom.io` + "/world/api/reservation/updatereservation";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando la reserva.");
		}
	}

	async getAvailableRooms() {
		const url = this.urlBase + "/world/reservation/availablerooms";
		try {
			return await sendRequest({data: {}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo las aviableRooms.");
		}
	}

	async checkAvailability(data) {
		const url = this.urlBase + "/world/reservation/checkavailability";
		try {
			return await sendRequest({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal comprobando la disponibilidad.");
		}
	}

	async cancelReservation(reservationId) {
		const url = this.urlBase + "/world/reservation/canceldirect";
		const data = {
			"reservationId": reservationId,
		};
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando reserva por email");
		}
	}

	async cancelAssistanceReservation(reservationId, userId) {
		const url = this.urlBase + "/world/reservation/removeinvitee";
		const data = {
			"reservationId": reservationId,
			"userId": userId,
		};
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando la cancelacion de la asistencia");
		}
	}

	async addInvitedUser(reservationWid, userId) {
		const url = this.urlBase + "/world/api/reservation/adduser"
		const data ={
			reservationWid, userId
		}
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal aceptando la reserva por invitacion.");
		}
	}

	async addUnknownInvitedUser(reservationWid, hostCode, invitedUserName) {
		const url = this.urlBase + "/world/api/reservation/addguest"
		try {
			let data = {
				createGuest: true,
				reservationWid,
				hostCode,
				invitedUserName
			}
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal aceptando la reserva por invitacion de usuario desconocido.");
		}
	}

	async getReservationsByDay(date) {

		const url = this.urlBase + "/world/api/reservation/getbyday"
		try {
			const data = {date}
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal pidiendo la informacion de reservas");
		}
	}

	async getTakeAway() {
		const url = this.urlBase + "/world/account/gettakeaway"
		try {
			return await sendRequestJson({data:{}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal en el getTakeAway");
		}
	}

	async setNoShow(reservationWid){
		const url = this.urlBase + "/world/api/reservation/set-no-show";

		let data = {
			reservationWid
		}

		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal finalizando la reserva.");
		}
	}
	async setPaymentPending(reservationWid, paymentMethod, uid = null, roleId = null) {
		const url = this.urlBase + "/world/api/reservation/request-ticket";

		let data = {
			reservationWid,
			paymentMethod,
			uid,
			roleId
		}

		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal finalizando la reserva.");
		}
	}

	async reactivateReservation(reservationWid) {
		const url = this.urlBase + "/world/api/reservation/reactivate";

		let data = {
			reservationWid,
		}

		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal reactivate la reserva.");
		}
	}

	async updatePax(data) {
		const url = this.urlBase + "/world/api/reservation/updatepax"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal modificando los pax");
		}
	}

	async createPayment(data) {
		const url = this.urlBase + "/world/api/payment/create";
		try {
			return await sendRequestJson({data, url, method: 'POST'})
		} catch (e) {
			console.log("Algo ha salido mal en createPayment")
		}
	}

	async getPaymentInfo(data) {
		const url = this.urlBase + "/world/api/reservation/payment-info";
		try {
			return await sendRequestJson({data, url, method: 'POST'});
		} catch (e) {
			console.log("Algo ha salido mal en getPaymentInfo")
		}
	}

	async getBillPaymentInfo(data) {
		const url = this.urlBase + "/world/api/reservation/bill-payment-info";
		try {
			return await sendRequestJson({data, url, method: 'POST'});
		} catch (e) {
			console.log("Algo ha salido mal en getPaymentInfo")
		}
	}

	async getBillPrintInfo(data) {
		const url = this.urlBase + "/world/api/bill/print-info";
		try {
			return await sendRequestJson({data, url, method: 'POST'});
		} catch (e) {
			console.log("Algo ha salido mal en getPaymentInfo")
		}
	}

	async enableReservation(data) {
		const url = this.urlBase + "/world/api/reservation/enable-reservation"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal habilitando la reserva.");
		}
	}

	async unifyReservations(data) {
		const url = this.urlBase + "/world/reservation/merge"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal habilitando la reserva.");
		}
	}

	async markProductOrdersPaid(data) {
		const url = this.urlBase + "/world/api/productorder/mark-as-paid"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal marcando productos como pagados.");
		}
	}

	async moveProductsOrdersReservation(data){
		const url = this.urlBase + "/world/api/productorder/modify-reservation"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal habilitando la reserva.");
		}
	}

	async updateLocationTag(data){
		const url = this.urlBase + "/world/reservation/update-location-tag"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal habilitando la reserva.");
		}
	}

	async addInternalCommentReservation(data){
		const url = this.urlBase + "/world/reservation/update-staff-comments"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal habilitando la reserva.");
		}
	}

	async updateMethodPaymentReservation(data){
		const url = this.urlBase + "/world/api/payment/update-method";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando el método de pago.");
		}

	}

	async paidReservation(data){
		const url = this.urlBase + "/world/api/reservation/paid-reservation";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando el paidReservation.");
		}

	}

	async viewBillPdf(reservationId) {
		const url = `${this.urlBase}/world/api/bill/view-bill-pdf`;
		// Assuming the backend expects a POST with a JSON payload:
		const payload = { billId: reservationId };
		try {
			return await sendRequestPdf({
				data: payload,
				url,
				method: "POST",
			});
		} catch (error) {
			console.error("Error fetching the bill PDF:", error);
			throw error;
		}
	}
}

export const reservationRepository = new ReservationRepository();
