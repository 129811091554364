<template>
	<button type="button" class="btn btn-light" :data-bs-dismiss="dismissAction" @click="handleClick">
		{{ buttonText }}
	</button>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export default {
	name: "DismissButton",
	props: {
		isModal: {
			type: Boolean,
			default: true,
		},
		contentBtnText: {
			type: String,
			default: "",
		},
		onClick: {
			type: Function,
			default: null,
		},
	},
	setup(props) {
		const { t } = useI18n();

		const buttonText = computed(() => {
			return props.contentBtnText || t("menu.orderCloseButton");
		});

		const dismissAction = computed(() => {
			return props.isModal ? "modal" : "offcanvas";
		});

		const handleClick = () => {
			if (props.onClick) {
				props.onClick();
			}
		};

		return { t, buttonText, dismissAction, handleClick };
	},
};
</script>
