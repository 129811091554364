import VueScrollTo from "vue-scrollto";
import * as bootstrap from "bootstrap";
import { cookieManager } from "@/service/cookieManager";
import { gsap } from "gsap";

export async function sendRequest({ data, url, method, contentType = "application/x-www-form-urlencoded" }) {
	try {
		const token = cookieManager.getToken();
		let options = {
			method: method,
			headers: {
				"Content-Type": contentType,
			},
		};

		if (token) {
			options.headers["Authorization"] = `Bearer ${token}`;
		}

		const dataToSend = contentType.includes("json") ? JSON.stringify(data) : new URLSearchParams(data);
		options.body = dataToSend;

		const response = await fetch(url, options);

		if (!response.ok) {
			throw new Error("Error en la solicitud: " + response.status);
		}

		return await response.json();
	} catch (error) {
		console.error("Error:", error);
	}
}

export async function sendRequestJson({ data, url, method }, sendToken = true) {
	const token = cookieManager.getToken();

	let options = {
		method: method,
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	};

	if (token && sendToken) {
		options.headers["Authorization"] = `Bearer ${token}`;
	}

	if (data) {
		options.body = JSON.stringify(data);
	}

	try {
		const response = await fetch(url, options);

		if (!response.ok) {
			return response.status;
		}

		return await response.json();
	} catch (error) {
		console.error("Error:", error);
		return error;
	}
}

export async function sendRequestPdf({ data, url, method }, sendToken = true) {
	const token = cookieManager.getToken();

	const options = {
		method: method,
		headers: {
			// When sending JSON data, still use JSON content-type.
			"Content-Type": "application/json",
			// Indicate that you expect a PDF in response.
			Accept: "application/pdf",
		},
	};

	if (token && sendToken) {
		options.headers["Authorization"] = `Bearer ${token}`;
	}

	if (data) {
		options.body = JSON.stringify(data);
	}

	try {
		const response = await fetch(url, options);

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		// Return the response as a Blob (which is suitable for binary data like PDFs)
		return await response.blob();
	} catch (error) {
		console.error("Error in sendRequestBlob:", error);
		throw error;
	}
}

const monthsFull = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

export const monthsShor = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const weekdaysFull = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

const weekdaysShort = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sáb"];

export const settingsDatePicker = {
	format: "dddd, d !de mmmm",
	hiddenName: true,
	formatSubmit: "yyyy-mm-dd",
	firstDay: 1,
	monthsFull: monthsFull,
	monthsShort: monthsShor,
	weekdaysFull: weekdaysFull,
	weekdaysShort: weekdaysShort,
	today: "Hoy",
	clear: "Borrar",
	close: "Cerrar",
	labelMonthNext: "Mes siguiente",
	labelMonthPrev: "Mes anterior",
	labelMonthSelect: "Selecciona un mes",
	labelYearSelect: "Selecciona un año",
	klass: {
		highlighted: false,
	},
	//min: [2023, 11, 15],
	min: true,
	max: 60,
	disable: [],
};

export function scrollToVue({ element, time = 3500, focus = false }) {
	const options = {
		easing: [0.25, 0.1, 0.25, 1.0],
		duration: time,
		// offset: -(document.getElementById('wewelcome-menu').offsetHeight)
	};
	// const menuElement = document.getElementById('wewelcome-menu');
	// menuElement ? options.offset = -menuElement.offsetHeight : null;
	document.documentElement.style.scrollBehavior = "auto";
	this.showToast("productSavedCorrectly");

	VueScrollTo.scrollTo(element, options);
	if (focus) {
		element.focus();
	}
}

export function validateEmail(email) {
	const emailPattern = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,5}|[0-9]{1,3})(\]?)$/;
	return emailPattern.test(email);
}

export function hideAllModals() {
	const modals = document.querySelectorAll(".modal");
	modals.forEach(function (modal) {
		var bootstrapModal = bootstrap.Modal.getInstance(modal); // Get the Bootstrap modal instance
		if (bootstrapModal) {
			bootstrapModal.hide(); // Hide the modal
		}
	});
}

export function hideModal(id) {
	const modalElement = document.getElementById(id);
	if (modalElement) {
		const modal = bootstrap.Modal.getInstance(modalElement);
		if (modal) {
			modal.hide();
		}
	}
}

export function hideAllOffcanvas() {
	const modals = document.querySelectorAll(".offcanvas");
	modals.forEach(function (modal) {
		var bootstrapModal = bootstrap.Offcanvas.getInstance(modal); // Get the Bootstrap modal instance
		if (bootstrapModal) {
			bootstrapModal.hide(); // Hide the modal
		}
	});
}

export function showModal(modalId) {
	const modalElement = document.getElementById(modalId);
	if (!modalElement) {
		console.error(`Modal element not found: ${modalId}`);
		return;
	}

	console.log(`Existe modal con id ${modalId}`);
	let modalInstance = bootstrap.Modal.getInstance(modalElement);
	if (!modalInstance) {
		console.log("No existía el modal, creating a new instance");
		modalInstance = new bootstrap.Modal(modalElement, {
			backdrop: "static",
			keyboard: false,
		});
	} else {
		console.log("Ya existía el modal del qr, using existing instance");
	}

	modalInstance.show();
}

export function showOffcanvas(id) {
	const offcanvasElement = document.getElementById(id);
	if (offcanvasElement) {
		let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
		if (!offcanvasInstance) {
			offcanvasInstance = new bootstrap.Offcanvas(offcanvasElement);
		}
		offcanvasInstance.show();
	} else {
		console.error("Offcanvas element not found:");
	}
}

export function capitalize(string) {
	return string.toLowerCase().replace(/[A-Za-zÀ-ÖØ-öø-ÿ]+/g, (c) => c[0].toUpperCase() + c.substring(1));
}

export function hideOffcanvas(id) {
	const offcanvasElement = document.getElementById(id);
	if (offcanvasElement) {
		const offCanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
		if (offCanvas) {
			offCanvas.hide();
		}
	}
}

export function filterReservationsByService(reservations) {
	const serviceTimes = {
		Desayuno: { start: 7, end: 12 },
		Comida: { start: 13, end: 17 },
		Cena: { start: 18, end: 24 },
	};

	const services = Object.entries(serviceTimes)
		.map(([serviceName, timeRange]) => {
			const serviceReservations = reservations.filter((reservation) => {
				const startTime = parseInt(reservation.startDate.split(":")[0]);
				const isWithinTimeRange = startTime >= timeRange.start && (timeRange.end ? startTime <= timeRange.end : true);
				const isActive = reservation.status !== 0;
				return isWithinTimeRange && isActive;
			});
			return { serviceName, serviceReservations };
		})
		.filter((service) => service.serviceReservations.length > 0);

	return services;
}

export function arraysAreEqualIgnoringOrder(arr1, arr2) {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
		console.error("Both arguments must be arrays:", arr1, arr2);
		return false;
	}

	if (arr1.length !== arr2.length) {
		return false;
	}

	const copyArr1 = arr1.slice();
	const copyArr2 = arr2.slice();

	while (copyArr1.length > 0) {
		const currentElement = copyArr1.pop();
		const indexInArr2 = copyArr2.findIndex((el) => deepCompare(currentElement, el));

		if (indexInArr2 === -1) {
			return false;
		}

		copyArr2.splice(indexInArr2, 1);
	}

	return true;
}

export function deepCompare(obj1, obj2) {
	if (obj1 === null || obj2 === null) {
		return obj1 === obj2; // Solo serán iguales si ambos son `null`
	}

	if (typeof obj1 !== "object" || typeof obj2 !== "object") {
		return obj1 === obj2;
	}

	const keys1 = Object.keys(obj1).sort();
	const keys2 = Object.keys(obj2).sort();

	if (keys1.length !== keys2.length) {
		return false;
	}

	return keys1.every((key) => {
		if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
			return arraysAreEqualIgnoringOrder(obj1[key], obj2[key]);
		}
		return deepCompare(obj1[key], obj2[key]);
	});
}

export function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function objectsAreEqualIgnoringOrder(obj1, obj2) {
	if (typeof obj1 !== "object" || typeof obj2 !== "object") {
		console.error("Both arguments must be objects:", obj1, obj2);
		return false;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (!arraysAreEqualIgnoringOrder(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
}

export const groupItemsBySkuAndChoices = (orders) => {
	const groupedItems = {};

	orders.forEach((order) => {
		const visibleItems = order.items.filter((item) => item.isVisible);

		visibleItems.forEach((item) => {
			const choicesKey = item.choices.map((choice) => `${choice.optionTypeId}:${choice.productChoiceId}`).join("|");
			const key = `${item.productSku}|${choicesKey}`;

			if (groupedItems[key]) {
				groupedItems[key].count += item.quantity || 1;
			} else {
				groupedItems[key] = {
					...item,
					choicesKey,
					count: item.quantity || 1,
					choices: item.choices.map((choice) => ({
						...choice,
						quantity: choice.quantity || 1,
					})),
				};
			}
		});
	});

	return groupedItems;
};

export function normalizeChoices(choices) {
	if (!choices) return [];
	if (Array.isArray(choices)) return choices;
	return Object.entries(choices)
		.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
		.map(([key, value]) => ({
			optionId: key,
			chosenIds: Array.isArray(value) ? value.sort() : [value],
		}));
}

export const printChoices = (choices) => {
	return choices
		.map((choice) => {
			// Condición para aplicar el formato <strong> solo si la cantidad es mayor que 1
			const quantityDisplay = choice.quantity > 1 ? `<strong>${choice.quantity}</strong>` : ``;
			return `${quantityDisplay} ${choice.choice}`;
		})
		.join(", "); // Unir todos los elementos con una coma
};

export function delay(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function animateProductToCart(productSku, cartIconId) {
	const product = document.getElementById(productSku);
	const cartIcon = document.getElementById(cartIconId);

	if (!product || !cartIcon) {
		console.error("No se pudo encontrar el producto o el carrito en el DOM.");
		return;
	}

	const flyingProduct = product.cloneNode(true);
	flyingProduct.classList.add("flyProduct");
	document.body.appendChild(flyingProduct);

	const productRect = product.getBoundingClientRect();
	const cartRect = cartIcon.getBoundingClientRect();

	flyingProduct.style.position = "fixed";
	flyingProduct.style.left = `${productRect.left}px`;
	flyingProduct.style.top = `${productRect.top}px`;
	flyingProduct.style.width = `${productRect.width}px`;

	gsap.to(flyingProduct, {
		x: cartRect.left + cartRect.width / 2 - (productRect.left + productRect.width / 2),
		y: cartRect.top + cartRect.height / 2 - (productRect.top + productRect.height / 2),
		scale: 0.1,
		opacity: 0,
		duration: 1.2,
		ease: "power2.inOut",
		onComplete: () => {
			flyingProduct.remove();
		},
	});

	gsap.fromTo(
		cartIcon,
		{ scale: 1 },
		{
			scale: 1.2,
			duration: 0.3,
			ease: "power1.inOut",
			yoyo: true,
			repeat: 1,
		}
	);
}

export function sanitizeString(input) {
	const charMap = {
		á: "a",
		é: "e",
		í: "i",
		ó: "o",
		ú: "u",
		ü: "u",
		Á: "A",
		É: "E",
		Í: "I",
		Ó: "O",
		Ú: "U",
		Ü: "U",
		ñ: "n",
		Ñ: "N",
		ç: "c",
		Ç: "C",
		ß: "ss",
		"\n": " ",
		"\t": " ",
	};
	return input
		.split("")
		.map((char) => charMap[char] || char)
		.join("");
}

export function roundUp(value, decimals = 2) {
	const multiplier = Math.pow(10, decimals);
	return Math.ceil(value * multiplier) / multiplier;
}

export function formatPrice(value, forceDecimals = false, decimalPlaces = 2) {
	const formatted = value % 1 === 0 && !forceDecimals ? value.toString() : value.toFixed(decimalPlaces).replace(".", ",");
	return formatted + " €";
}

export function stripAndroidPrinterTags(input) {
	// Remove custom tags like [L], [R], [C]
	let output = input.replace(/\[[LRC]\]/g, "");
	// Remove HTML tags
	output = output.replace(/<\/?[^>]+(>|$)/g, "");
	return output;
}

export function closeAllCollapses() {
	const collapses = document.querySelectorAll(".collapse.show"); // Seleccionar todos los collapses abiertos
	collapses.forEach((collapse) => {
		const collapseInstance = bootstrap.Collapse.getInstance(collapse);
		if (collapseInstance) {
			collapseInstance.hide(); // Usar la API de Bootstrap para cerrar el collapse
		}
	});
}

export function closeDropdown(id) {
	let dropdownElement = document.getElementById(id);
	let dropdownInstance = bootstrap.Dropdown.getInstance(dropdownElement);
	if (dropdownInstance) {
		dropdownInstance.hide();
	}
}

export function setElementHeight(selector, height) {
	const element = document.getElementById(selector);
	if (element) {
		element.style.height = height;
	}
}

export function viewSectionAccounts() {
	setElementHeight("section-tpv-accounts", "90vh");
	setElementHeight("section-tpv-drinks-to-prepare", "10vh");
}

export function viewSectionDrinksToPrepare() {
	closeAllCollapses();
	setElementHeight("section-tpv-accounts", "10vh");
	setElementHeight("section-tpv-drinks-to-prepare", "90vh");
}

export function viewSectionStandar() {
	closeAllCollapses();
	setElementHeight("section-tpv-accounts", "50vh");
	setElementHeight("section-tpv-drinks-to-prepare", "50vh");
}

export function hasDubbing(reservations, table) {
	const now = new Date();
	// Filtra reservas que están dentro de las próximas 24 horas y aún no han comenzado
	const upcomingReservations = reservations.filter(
		(reservation) =>
			reservation.table === table.name &&
			reservation.status !== 7 &&
			reservation.status !== 10 &&
			reservation.menuAccessEnabled === 0 &&
			new Date(reservation.startDateTime) > now && // Debe empezar en el futuro
			new Date(reservation.startDateTime) <= new Date(now.getTime() + 24 * 60 * 60 * 1000) // Dentro de las próximas 24 horas
	);

	return upcomingReservations.length > 0; // Devuelve true si hay al menos una reserva próxima
}

export function remainingTimeToReservation(reservations, table) {
	// Obtener la hora actual
	const now = new Date();

	// Filtrar las reservaciones futuras para la mesa seleccionada
	const upcomingReservations = reservations.filter((reservation) => reservation.table === table.name && reservation.status !== 7 && reservation.status !== 10 && new Date(reservation.startDateTime) > now);

	// Si no hay próximas reservas, devuelve 'N/A'
	if (upcomingReservations.length === 0) {
		return "N/A";
	}

	// Ordenar por hora de inicio para encontrar la próxima reserva
	upcomingReservations.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
}
