export function detectOS() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	if (/windows phone/i.test(userAgent)) {
		return "Windows Phone";
	}
	if (/android/i.test(userAgent)) {
		return "Android";
	}
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "iOS";
	}
	if (/Win/i.test(userAgent)) {
		return "Windows";
	}
	if (/Mac/i.test(userAgent)) {
		return "MacOS";
	}
	if (/Linux/i.test(userAgent)) {
		return "Linux";
	}

	return "Unknown";
}

export function parseDecimal(value){
	if (!value) return 0;
	// Normalize by replacing comma with dot and trim whitespace
	const normalized = value.toString().trim().replace(',', '.');
	const number = parseFloat(normalized);
	return isNaN(number) ? 0 : number;
}
