import {sendRequestJson} from '@/utils'

export class BillRepository {

	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('BillRepository con urlBase: ', this.urlBase)
	}

	async createInvoiceNominativeData(invoiceId, clientData) {
		const url = `${this.urlBase}/world/api/bill/create-nominative-invoice`;
		// Assuming the backend expects a POST with a JSON payload:
		const payload = { billId: invoiceId, clientData };
		try {
			return await sendRequestJson({
				data: payload,
				url,
				method: "POST",
			});
		} catch (error) {
			console.error("Error creating the nominative invoice:", error);
			throw error;
		}
	}
}

export const billRepository = new BillRepository();
