import reservationUtils from "@/utils/reservationUtils";
import { hideAllOffcanvas, showOffcanvas, hideAllModals, showModal } from "@/utils";
import store from "@/store/index";
import { reservationRepository } from "@/service/repository/reservationRepository";

const state = () => ({
	reservation: null,

	isUpdateMethodPayment: false,

	statusStart: 1,
	statusNoShow: -4,
	statusForPayment: 7,
});

const getters = {
	reservation: (state) => state.reservation,

	isUpdateMethodPayment: (state) => {
		return state.isUpdateMethodPayment;
	},

	totalPrice: (state) => {
		if (!state.reservation || !state.reservation.paymentInfo) {
			return "0,00";
		}
		return state.reservation.paymentInfo.total.toFixed(2).replace(".", ",");
	},

	getTickets: (state) => {
		return reservationUtils.getBills(state.reservation);
	},
	getInvoices: (state) => {
		return reservationUtils.getSimplifiedInvoices(state.reservation);
	},
};

const mutations = {
	setReservation: (state, reservation) => {
		state.reservation = reservation;
	},
	setIsUpdateMethodPayment: (state, isUpdateMethodPayment) => {
		state.isUpdateMethodPayment = isUpdateMethodPayment;
	},
};

const actions = {
	updateReservation({ commit }, reservation) {
		commit("setReservation", reservation);
	},

	async processPaymentPending(_, { reservationId, methodPayment, uid, selectedRole }) {
		try {
			const resultRequestTicket = await reservationRepository.setPaymentPending(reservationId, methodPayment, uid, selectedRole);

			if (resultRequestTicket.result === 0) {
				hideAllOffcanvas();
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido marcar el método de pago.`,
						showToast: true,
						idToast: "incorrectlyToast",
					},
				});
			}
			return resultRequestTicket;
		} catch (error) {
			console.error("Error en el pago:", error);
			store.commit("copilot/setToast", {
				toast: {
					toastMessage: `Error en el pago: ${error.message}`,
					showToast: true,
					idToast: "incorrectlyToast",
				},
			});
		}
	},

	async reactivateReservation(_, reservationId) {
		try {
			const resultReactivateReservation = await reservationRepository.reactivateReservation(reservationId);

			if (resultReactivateReservation.result === 0) {
				hideAllOffcanvas();
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido reactivar la cuenta.`,
						showToast: true,
						idToast: "incorrectlyToast",
					},
				});
			}
		} catch (error) {
			console.error("Error al reactivar la reserva:", error);
			store.commit("copilot/setToast", {
				toast: {
					toastMessage: `Error al reactivar la reserva: ${error.message}`,
					showToast: true,
					idToast: "incorrectlyToast",
				},
			});
		}
	},

	async paidReservation({ commit, dispatch }, data) {
		try {
			const resultPaidReservation = await reservationRepository.paidReservation(data);

			if (resultPaidReservation.result === 0) {
				hideAllModals();
				if (resultPaidReservation.bill !== null) {
					await dispatch("weWingman/printBill", resultPaidReservation.bill, { root: true });
				}
				commit("setIsUpdateMethodPayment", false);
				commit("setReservation", null);
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido marcar como pagada la cuenta.`,
						showToast: true,
						idToast: "incorrectlyToast",
					},
				});
			}

			return resultPaidReservation;
		} catch (error) {
			console.error("Error al pagar la reserva:", error);

			store.commit("copilot/setToast", {
				toast: {
					toastMessage: `Error al pagar la reserva: ${error.message}`,
					showToast: true,
					idToast: "incorrectlyToast",
				},
			});

			return { result: -1, error };
		}
	},

	async updatePaxReservation(_, { reservationId, numberPax }) {
		try {
			const data = {
				reservationWid: reservationId,
				pax: numberPax,
			};
			const resultUpdatedPaxReservation = await reservationRepository.updatePax(data);

			if (resultUpdatedPaxReservation.result === 0) {
				hideAllModals();
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `Ha ocurrido un error actualizando los comensales correctamente.`,
						showToast: true,
						idToast: "incorrectlyToast",
					},
				});
			}

			return resultUpdatedPaxReservation;
		} catch (error) {
			console.error("Error al actualizar las personas en la reserva:", error);

			store.commit("copilot/setToast", {
				toast: {
					toastMessage: `Error al actualizar las personas en la reserva: ${error.message}`,
					showToast: true,
					idToast: "incorrectlyToast",
				},
			});

			return { result: -1, error };
		}
	},
	openCreateInternalComment({ commit }, reservation) {
		commit("setReservation", reservation);

		showOffcanvas("offcanvas-internal-comment");
	},
	openPaidAccount({ commit }, reservation) {
		commit("setReservation", reservation);
		commit("setIsUpdateMethodPayment", true);

		setTimeout(() => {
			showModal("confirm-close-" + reservation.id);
		});
	},
	openFinishReservation({ commit }, reservation) {
		commit("setReservation", reservation);

		setTimeout(() => {
			showModal("confirm-close-" + reservation.id);
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
