
function getTickets(reservation) {
    if (!reservation || !reservation.paymentInfo || !Array.isArray(reservation.paymentInfo.bills)) {
        return [];
    }
    return reservation.paymentInfo.bills.filter(bill => bill.type === 0);
}

function getInvoices(reservation) {
    if (!reservation || !reservation.paymentInfo || !Array.isArray(reservation.paymentInfo.bills)) {
        return [];
    }
    return reservation.paymentInfo.bills.filter(bill => bill.type === 1);
}


export default {
    getBills: getTickets,
    getSimplifiedInvoices: getInvoices,

};
